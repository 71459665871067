import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "./modal.scss";
import {
  adminRequestPost,
  adminUserPut,
  requestGet,
} from "../../../apis/request";

function CashHandModal({ setModal, userId, callInfo, callAPI }) {
  const [value, setValue] = useState("");
  const [directType, setDirectType] = useState("");
  const [directCardCompany, setDirectCardCompany] = useState("");
  const [directCardMonthlyPayment, setDirectCardMonthlyPayment] = useState("");
  const [directPaymentNumber, setDirectPaymentNumber] = useState("");
  const [memo, setMemo] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [time, setTime] = useState("");
  const [amount, setAmount] = useState("");
  const [desc, setDesc] = useState("");
  const [balance, setBalance] = useState(0);
  const [splitTotalPrice, setSplitTotalPrice] = useState("");
  const [splitName, setSplitName] = useState("");
  const [mainDesc, setMainDesc] = useState("");
  const [couponType, setCouponType] = useState("");
  const [couponList, setCouponList] = useState([]);
  const [splitMainProductId, setSplitMainProductId] = useState("");
  const [installment, setInstallment] = useState(false);
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState(0);
  
  // 분할결제 목록 불러오기
  // useEffect(() => {
  //   if (installment) {
  //    callInfo();
  //   }
  // }, [installment]);
  const callListInfo = async () => {
    setSelectedParentId(0);
    var data = [];
      data[0] =  {
        "id": 0,
        "impUid": "",
        "merchantId": "",
        "pgName": "",
        "method": "",
        "status": "",
        "userId": 0,
        "userName": "",
        "productId": 0,
        "productName": "신규 생성",
        "productPrice": 0,
        "productType":"",
        "productLikeCoupon":0,
        "productDays":0,
        "orderer": "",
        "phone": "",
        "email": "",
        "createdAt": "",
        "splitName":"",
        "balance":0,
      };
    try {
      const params = new URLSearchParams();
      
      params.append("userId", userId);
      params.append("isSplit", true);
      const memberResponse = await requestGet(
        `/api/purchase/list?${params}`
      );
      
      if(memberResponse?.data){ 
        data = data.concat(memberResponse?.data);
      }
      setInstallmentOptions(data);
      
    } catch (error) {
      
      setInstallmentOptions(data);
      console.log(error);
    }
  };

  const callCouponList = async () => {
    try {
      if (couponList&&couponList.length ===0){
        const memberResponse = await requestGet(
          `/api/product/coupon/list`
        );
        let data=[]
        if(memberResponse?.data){ 
          data = data.concat(memberResponse?.data);
        }
        setCouponList(data)
      }

      
    } catch (error) {
  
      console.log(error);
    }
  };

  useEffect(() => {
    callListInfo();
    callCouponList()
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  
  const firstCallApi = async () => {
    if (!name) {
      return alert("상품 이름을 입력해 주세요.");
    }
    if (!type) {
      return alert("종류를 선택해 주세요.");
    }
    if (!price) {
      return alert("가격을 입력해 주세요.");
    }
    if (type === "subscribe" && !time) {
      return alert("기간을 입력해 주세요.");
    }
    if ((type === "joinFee" || type === "purchaseItem") && !amount) {
      return alert("매칭권 개수를 입력해 주세요.");
    }

    if (installment){
      if (!splitName) {
        return alert("분할 상품 이름을 입력해 주세요.");
      }
    }

    try {
      let body = {
        name: name,
        type: type,
        price: Number(price.replace(/\D/g, "")),
        desc: desc,
      };
      

      if (type === "subscribe") {
        body.days = Number(time);
      } else if (type === "joinFee" || type === "purchaseItem") {
        body.likeCoupon = Number(amount);
        body.couponType=couponType
      }
      let mainProductId;
      if(installment){
        body.name=splitName
        body.splitTotalPrice = Number(splitTotalPrice.replace(/\D/g, ""));
        if(selectedParentId>0){
          if (Number(body.price) > balance) {
            return alert("분할 결제 가격을 다시 입력해주세요.(잔금 보다 큽니다.)");
          }
        }else{
          if (Number(body.price) > Number(body.splitTotalPrice)) {
            return alert("분할 결제 가격을 다시 입력해주세요.(원본 가격보다 큽니다.)");
          } 
        }
        if(selectedParentId ===0){
          let body2={
            name: name,
            type: type,
            price: Number(splitTotalPrice.replace(/\D/g, "")),
            desc: mainDesc,
          };      
          if (type === "subscribe") {
            body2.days = Number(time);
          } else if (type === "joinFee" || type === "purchaseItem") {
            body2.likeCoupon = Number(amount);
            body2.couponType=couponType
          }
          if (!splitMainProductId){
            let response2 = await adminRequestPost("/api/product/direct", body2);
            if (response2){
              mainProductId=response2.data.id;
            }
          }
        }else{
          mainProductId=splitMainProductId;
        }

        body.isSplit = true;
      }
      const response = await adminRequestPost("/api/product/direct", body);
      console.log(mainProductId)
   
      callApi(response.data.id,mainProductId);
    } catch (error) {
      alert(error?.data?.message);
    }
  };

  const callApi = async (id,splitMainProductId=undefined) => {
    try {
      let body = {
        userId: userId,
        productId: id,
        memo: memo,
        directType: directType,
        directPrice: price,
        directCardCompany: directCardCompany,
        directCardMonthlyPayment: Number(directCardMonthlyPayment),
        directPaymentNumber: directPaymentNumber,
        status: "complete",
 
      };
      console.log('1111',id,splitMainProductId)
      console.log('2',installment,selectedParentId,Number(price.replace(/\D/g, "")),balance,Number(price.replace(/\D/g, "")) > balance)
      if(installment){
        body.splitTotalPrice = Number(splitTotalPrice.replace(/\D/g, ""));
        if(selectedParentId >0){
          body.splitMainId = selectedParentId;
          if (Number(price.replace(/\D/g, "")) > balance) {
            return alert("가격을 다시 입력해주세요.(잔금 보다 큽니다.)");
          }
        }
        if (splitMainProductId){
          body.splitMainProductId=splitMainProductId
        }
        body.isSplit = true;
      }
      await adminRequestPost("/api/purchase/direct", body);
      callInfo();
      alert("생성 완료 되었습니다.");
      if (callAPI) {
        callAPI();
      }
      setModal(false);
    } catch (error) {
      console.log(error);
      alert("생성 실패 하였습니다.");
      setModal(false);
    }
  };

  const onClose = async () => {
    setModal(false);
  };

  const setCommaOnPrice=(value)=>{
    if (value){
      try{
        const formattedNumber = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedNumber
      }catch(error){
        console.log(error)
        return value
      }

    }else{
      return ''
    }
  }

  const onChangePrice = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    const formattedNumber = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setPrice(formattedNumber);
  };
  const onChangeSplitTotalPrice = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    const formattedNumber = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setSplitTotalPrice(formattedNumber);
  };
  const onChangeSplitTotalPrice2 = (e) => {
    const value = e.replace(/\D/g, "");
    const formattedNumber = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setSplitTotalPrice(formattedNumber);
  };
  function numberToKorean(number) {
    let num = number ? number : "0";
    if (typeof number === "string") {
      num = number.replace(/\D/g, "");
    }
    const units = ["", "만", "억", "조", "경"];
    const nums = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
    let result = "";
    let unit = 10000;

    if (num === 0) {
      return "영";
    }

    for (let u of units) {
      const curr = num % unit;
      num = Math.floor(num / unit);

      if (curr !== 0) {
        let currStr = "";
        const currArr = String(curr).split("").reverse();

        for (let i = 0; i < currArr.length; i++) {
          const digit = currArr[i];
          if (digit !== "0") {
            currStr =
              nums[parseInt(digit)] +
              (i > 0 ? ["", "십", "백", "천"][i] : "") +
              currStr;
          }
        }
        result = currStr + u + result;
      }

      if (num === 0) {
        break;
      }
    }

    // "일"을 제거하는 조건을 보다 구체적으로 설정하여, "일억"과 같은 경우에는 "일"을 제거하지 않음
    return (result += "원");
  }
  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard py-3 ps-3 pe-2">
              <h2>결제 조회</h2>
              {/* 분할 결제 여부 */}
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex align-items-center">
                  <label className="me-3 labelText">분할 결제 여부</label>
                  <input
                    type="checkbox"
                    name="installment"
                    // checked={installment}
                    onChange={(e) => {
                      console.log("e.target.checked", e.target.checked);
                      setInstallment(e.target.checked)
                      setSelectedParentId(0);
                      setSplitTotalPrice("");
                      console.log("installment", installment);
                    }}
                    
                    aria-label="분할 결제 여부 선택"
                  />
                </Col>
              </Row>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor="nameLabel" className="labelText">
                      상품 이름
                    </label>
                    {installment ? (
                      <select
                        className="form-select customInput"
                        name="name"
                        id="nameLabel"
                        aria-label="상품 이름을 선택해 주세요."
                        value={selectedParentId}
                        onChange={(e) =>{
                          console.log("vaue:", e.target.value);
                          setSelectedParentId(Number(e.target.value));
                          try {
                            const productPrice = installmentOptions.find(item => String(item.id) === String(e.target.value))?.splitTotalPrice;
                            console.log("productPrice", productPrice);
                            console.log("installmentOptions", installmentOptions);
                            const productType = installmentOptions.find(item => String(item.id) === String(e.target.value))?.productType;
                            console.log("productType",productType)
                            setType(productType);
                            const productDays = installmentOptions.find(item => String(item.id) === String(e.target.value))?.productDays;
                            if (productDays!==undefined){
                              setTime(productDays)
                            }
                            const productLikeCoupon = installmentOptions.find(item => String(item.id) === String(e.target.value))?.productLikeCoupon;
                            if (productLikeCoupon!==undefined){
                              setAmount(productLikeCoupon)
                            }
                            if(productPrice!==undefined){
                              onChangeSplitTotalPrice2(String(productPrice));
                            }
                            const balancePrice = installmentOptions.find(item => String(item.id) === String(e.target.value))?.balance;
                            if (balancePrice!==undefined){
                              setBalance(balancePrice)
                            }
                            
                            const mainDescData = installmentOptions.find(item => String(item.id) === String(e.target.value))?.mainDesc;
                            if (mainDescData!==undefined){
                              setMainDesc(mainDescData)
                            }
                            if (e.target.value>0){
                              const splitMainProductIdData = installmentOptions.find(item => String(item.id) === String(e.target.value))?.splitMainProductId;
                              let nameData;
                              if (splitMainProductIdData){
                                nameData=installmentOptions.find(item => String(item.id) === String(e.target.value))?.mainName;
                                setSplitMainProductId(splitMainProductIdData)
                              }else{
                                nameData=installmentOptions.find(item => String(item.id) === String(e.target.value))?.productName;
                              }
                              if (nameData){
                                setName(nameData)
                              }
                              const coupontTypeData = installmentOptions.find(item => String(item.id) === String(e.target.value))?.couponType;
                              if (coupontTypeData){
                                setCouponType(coupontTypeData)
                              }
                            }else{
                              setName('')
                              setMainDesc('')
                              setSplitTotalPrice("");
                            }
                          } catch (error) {
                            console.log("error", error);
                            setSplitTotalPrice("");
                          }
                        } }
                      >
                        {installmentOptions.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.splitMainProductId?option.mainName:option.productName}
                          </option>
                        ))}
                      </select>
                    ) : (
                      null
                    )}
                    {selectedParentId===0?                   
                     <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="상품 이름을 입력해 주세요."
                        aria-label="상품 이름을 입력해 주세요."
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      :null}
                  </div> 
                </Col>
              </Row>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      종류
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      disabled={installment&&selectedParentId !==0?true:false}
                    >
                      {type === "" ? (
                        <option value="">선택해주세요.</option>
                      ) : null}
                      <option value="subscribe">기간제</option>
                      <option value="joinFee">가입비</option>
                      <option value="marriageFee">성혼사례비</option>
                      <option value="purchaseItem">추가매칭권</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {type === "subscribe" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        기간(일)
                      </label>
                      <input
                        type="number"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="기간을 입력해 주세요."
                        aria-label="기간을 입력해 주세요."
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        disabled={installment&&selectedParentId !==0?true:false}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {type === "joinFee" || type === "purchaseItem" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel3`} className="labelText">
                      매칭권 타입
                    </label>
                    <select
                      id="statusLabel3"
                      className="form-select customInput"
                      value={couponType}
                      onChange={(e) => setCouponType(e.target.value)}
                      disabled={installment&&selectedParentId !==0?true:false}
                    >
                      {type === "" ? (
                        <option value="">선택해주세요.</option>
                      ) : null}
                      {couponList&&couponList.length >0?couponList.map(x=>{
                        return      <option value={x.key}>{x.name}</option>
                      }) :null}
                    </select>
                  </div>
                  </Col>
                </Row>
              ) : null}
              {type === "joinFee" || type === "purchaseItem" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        매칭권 개수
                      </label>
                      <input
                        type="number"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="매칭권 개수를 입력해 주세요."
                        aria-label="매칭권 개수를 입력해 주세요."
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        disabled={installment&&selectedParentId !==0?true:false}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {installment ? (  
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`splitTotalPrice`} className="labelText">
                        원본 가격
                      </label>
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="splitTotalPrice"
                        id="splitTotalPrice"
                        placeholder="가격을 입력해 주세요."
                        aria-label="가격을 입력해 주세요."
                        value={splitTotalPrice}
                        onChange={(e) => {                
                          onChangeSplitTotalPrice(e)
                        } }
                        disabled={installment&&selectedParentId !==0?true:false}
                      />
                       <div>{numberToKorean(splitTotalPrice)}</div>
                    </div>
                  </Col>
                </Row>
                ) : null
              }

              {installment ? (  
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label className="labelText" for="marketing_agree">
                        원본 상품 설명
                      </label>
                      <textarea
                        className="js-input-mask form-control customInput"
                        value={mainDesc}
                        placeholder={`설명을 입력해 주세요.`}
                        aria-label={`설명을 입력해 주세요.`}
                        onChange={(e) => setMainDesc(e.target.value)}
                        rows={5}
                        style={{ resize: "none" }}
                        disabled={installment&&selectedParentId !==0?true:false}
                      />
                    </div>
                  </Col>
                </Row>
                ) : null
              }
              {installment &&selectedParentId!==0? (  
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <Row>
                        <Col className="labelText"> 남은 금액  : </Col>
                        <Col className="labelText"> {setCommaOnPrice(balance)}</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                ) : null
              }
              {installment?<Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor="splitNameLabel" className="labelText">
                     분할 상품 이름
                    </label>
                    <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="splitName"
                        id="splitNameLabel"
                        placeholder="분할 상품 이름을 입력해 주세요."
                        aria-label="분할 상품 이름을 입력해 주세요."
                        value={splitName}
                        onChange={(e) => setSplitName(e.target.value)}
                      />
                  </div>
                </Col>
              </Row>:null}
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                     {installment?'분할상품 가격' :'가격'}
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="가격을 입력해 주세요."
                      aria-label="가격을 입력해 주세요."
                      value={price}
                      onChange={(e) => onChangePrice(e)}
                    />
                    <div>{numberToKorean(price)}</div>
                  </div>
                </Col>
              </Row>

              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText" for="marketing_agree">
                    {installment?'분할상품 설명' :'설명'}
                    </label>
                    <textarea
                      className="js-input-mask form-control customInput"
                      value={desc}
                      placeholder={`설명을 입력해 주세요.`}
                      aria-label={`설명을 입력해 주세요.`}
                      onChange={(e) => setDesc(e.target.value)}
                      rows={5}
                      style={{ resize: "none" }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText">결제 타입</label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      value={directType}
                      onChange={(e) => setDirectType(e.target.value)}
                    >
                      {!directType ? (
                        <option value="">선택해주세요.</option>
                      ) : null}
                      <option value="card">카드</option>
                      <option value="bank">계좌이체</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {directType === "card" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        카드사
                      </label>
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="카드사를 입력해 주세요."
                        aria-label="카드사를 입력해 주세요."
                        value={directCardCompany}
                        onChange={(e) => setDirectCardCompany(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {directType === "card" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        할부
                      </label>
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="할부를 입력해 주세요."
                        aria-label="할부를 입력해 주세요."
                        value={directCardMonthlyPayment}
                        onChange={(e) =>
                          setDirectCardMonthlyPayment(e.target.value)
                        }
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {directType === "card" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        카드번호
                      </label>
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="카드번호를 입력해 주세요."
                        aria-label="카드번호를 입력해 주세요."
                        value={directPaymentNumber}
                        onChange={(e) => setDirectPaymentNumber(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText">메모</label>
                    <textarea
                      className="js-input-mask form-control customInput"
                      placeholder="메모를 입력해 주세요."
                      aria-label="메모를 입력해 주세요."
                      rows={5}
                      value={memo}
                      style={{ resize: "none" }}
                      onChange={(e) => setMemo(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-3">
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    color="danger"
                    style={{ marginRight: "10px" }}
                    onClick={() => onClose()}
                  >
                    닫기
                  </Button>
                  <Button
                    id="masterAccesBtn"
                    color="primary"
                    onClick={() => firstCallApi()}
                  >
                    등록
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default CashHandModal;
