import React, { useCallback, useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { Button, Card, CardBody, Col, Row,Alert,Container,Spinner } from "reactstrap";
import "react-rangeslider/lib/index.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import moment from "moment";
import "./schedule.scss";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import { useTotalListStore } from "../../store/zustand/state";
import { adminUserPut, requestGet,requestPut } from "../../apis/request";
// import "./consult.scss";

//lch1721 현재 사용하고 있는 통계 목록 
registerLocale("ko", ko);

function TotalTable(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tableHeight, setTableHeight] = useState(0);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const { list, updateList, updateActiveNum } = useTotalListStore();
  const [notification, setNotification] = useState(''); // 알림 메시지
  const [notificationType, setNotificationType] = useState('success'); // 알림 타입 ('success' | 'danger')
  const [showNotification, setShowNotification] = useState(false); // 알림 표시 여부
  const columns = [

    {
      dataField: "date",
      text: "날짜",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "updatedAt",
      text: "마지막 갱신 시간",
      sort: false,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
      formatter: (cellContent, row, rowIndex) => {

        if (row.updatedAt && row.updatedAt.length > 0) {
          // 한국 시간 문자열을 Date 객체로 변환
          const updatedDate = new Date(row.updatedAt.replace(' ', 'T') + '+09:00'); // KST 시간대 적용
          const now = new Date(); // 현재 시간 UTC 기준
          const kstNow = new Date(now.getTime()); // KST로 변환
          // 시간 차이 계산 (밀리초 단위)
          const diff = kstNow - updatedDate;
          const diffSeconds = Math.floor(diff / (1000)); // 분 단위
          const diffMinutes = Math.floor(diff / (1000 * 60)); // 분 단위
          const diffHours = Math.floor(diff / (1000 * 60 * 60)); // 시간 단위
          const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24)); // 일 단위
          const diffMonths = Math.floor(diff / (1000 * 60 * 60 * 24 * 30)); // 월 단위
      
          // 시간차 표시 문자열 생성
          let timeAgo = '';
          if (diffMonths > 0) {
            timeAgo = `${diffMonths}달 전`;
          } else if (diffDays > 0) {
            timeAgo = `${diffDays}일 전`;
          } else if (diffHours > 0) {
            timeAgo = `${diffHours}시간 전`;
          } else if (diffMinutes > 0) {
            timeAgo = `${diffMinutes}분 전`;
          } else if (diffSeconds > 0) {
            timeAgo = `${diffSeconds}초 전`;
          } else {
            timeAgo = '방금';
          }
      
          return (
            <Button color="primary" size="sm" onClick={(e) => handleUpdateAndFetch(row.date)}>
         {timeAgo}
            </Button>
          );
        } else {
          return <Button
          color="primary"
          size="sm"
          onClick={()=>handleUpdateAndFetch()}
        >

            {'전체 갱신'}

        </Button>;
        }


      },
    },
    {
      dataField: "reqMale",
      text: "상담신청자(남)",
      sort: true,
      // align: "end",
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "reqFemale",
      text: "상담신청자(여)",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "reqTotal",
      text: "상담신청자(합계)",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "reqEnd",
      text: "상담완료",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "reqPending",
      text: "상담보류",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "reqReject",
      text: "상담반려",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "reqCancel",
      text: "상담취소",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "memMale",
      text: "상담회원(남)",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "memFemale",
      text: "상담회원(여)",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "memTotal",
      text: "상담회원(합계)",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    // {
    //   dataField: "payrMale",
    //   text: "결제자(남)",
    //   sort: true,
    //   // headerStyle: {
    //   //   borderBottom: "1px solid #dcdcdc",
    //   // },
    //   style: {
    //     cursor: "default",
    //     // borderBottomColor: "#dcdcdc",
    //   },
    // },
    // {
    //   dataField: "payrFemale",
    //   text: "결제자(여)",
    //   sort: true,
    //   // headerStyle: {
    //   //   borderBottom: "1px solid #dcdcdc",
    //   // },
    //   style: {
    //     cursor: "default",
    //     // borderBottomColor: "#dcdcdc",
    //   },
    // },
    {
      dataField: "payrTotal",
      text: "결제자(합계)",
      sort: true,
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    // {
    //   dataField: "apprMale",
    //   text: "최종승인(남)",
    //   sort: true,
    //   // headerStyle: {
    //   //   borderBottom: "1px solid #dcdcdc",
    //   // },
    //   style: {
    //     cursor: "default",
    //     // borderBottomColor: "#dcdcdc",
    //   },
    // },
    // {
    //   dataField: "apprFemale",
    //   text: "최종승인(여)",
    //   sort: true,
    //   // headerStyle: {
    //   //   borderBottom: "1px solid #dcdcdc",
    //   // },
    //   style: {
    //     cursor: "default",
    //     // borderBottomColor: "#dcdcdc",
    //   },
    // },
    {
      dataField: "apprTotal",
      text: "최종승인(합계)",
      sort: true,
      headerStyle: {
        borderBottom: "1px solid #dcdcdc",
      },
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "likeMale",
      text: "호감(남)",
      sort: true,
      headerStyle: {
        borderBottom: "1px solid #dcdcdc",
      },
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "likeFemale",
      text: "호감(여)",
      sort: true,
      headerStyle: {
        borderBottom: "1px solid #dcdcdc",
      },
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "likeTotal",
      text: "호감(합계)",
      sort: true,
      headerStyle: {
        borderBottom: "1px solid #dcdcdc",
      },
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
    {
      dataField: "matchTotal",
      text: "맞호감",
      sort: true,
      headerStyle: {
        borderBottom: "1px solid #dcdcdc",
      },
      // headerStyle: {
      //   borderBottom: "1px solid #dcdcdc",
      // },
      style: {
        cursor: "default",
        // borderBottomColor: "#dcdcdc",
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const gohistory = () => {
    updateList(startDate, endDate, 1);
    
  };


  const refreshByDate = async (date) => {
    let url='/api/stats/user/update',body={}
    if (!date){
      url='/api/stats/user/update/all'
      body.startDate='2024-01-01'
    }else{
      body.date=date
    }

  try {

      await adminUserPut(url,body); // 데이터 갱신 API 호출
      return true; // 요청 성공
    } catch (error) {
      console.error('갱신 요청 중 에러:', error);
      return false; // 요청 실패
    }
  };
  const handleUpdateAndFetch = async (date=undefined) => {

    let target=date
    if (!date){
      target='전체'
    }

    try {
      const isUpdated = await refreshByDate(date); // 갱신 요청
      if (isUpdated) {
        // 갱신 성공 시 목록 요청
        // await callInfo();
        handleShowNotification(`${target} 통계가 성공적으로 갱신되었습니다.`, 'success');
      } else {
        // 갱신 실패 알림
        console.log(false)
        handleShowNotification(`${target} 통계의 갱신에 실패하였습니다.`, 'danger');
      }
    } catch (error) {
      console.error('갱신 및 목록 호출 중 에러:', error);
      handleShowNotification('예기치 않은 오류가 발생했습니다.', 'danger');
    }finally{
      await callInfo();
    }
  };
  const handleShowNotification = (message, type) => {
    setNotification(message);
    setNotificationType(type);
    setShowNotification(true);

    // 3초 후 알림 숨기기
    setTimeout(() => setShowNotification(false), 3000);
  };
  const callInfo = useCallback(async () => {

    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      const memberResponse = await requestGet(`/api/stats/user/list?${params}`);
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  useEffect(async() => {
    setStartDate(list.startDate);
    setEndDate(list.endDate);
    setActiveNum(list.page);
    callInfo();
  }, [callInfo, list]);

  const handleStartDate = (e) => {
    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
  };

  const handleEndDate = (e) => {
    setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      gohistory();
    }
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          date: item.date,
          updatedAt:item.updatedAt,
          reqMale: item.reqMale,
          reqFemale: item.reqFemale,
          reqTotal: item.reqTotal,
          reqEnd: item.reqEnd,
          reqPending: item.reqPending,
          reqReject: item.reqReject,
          reqCancel: item.reqCancel,
          memMale: item.memMale,
          memFemale: item.memFemale,
          memTotal: item.memTotal,
          payrMale: item.payrMale,
          payrFemale: item.payrFemale,
          payrTotal: item.payrTotal,
          apprMale: item.apprMale,
          apprFemale: item.apprFemale,
          apprTotal: item.apprTotal,
          likeMale: item.likeMale,
          likeFemale: item.likeFemale,
          likeTotal: item.likeTotal,
          matchTotal: item.matchTotal,
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  useEffect(() => {
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, productData]);
  return (
    <React.Fragment>

      <div className="custompage-content">
      {showNotification && (
                <div
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1050,
                  minWidth: '300px',
                  textAlign: 'center',
                }}
              >
        <Alert color={notificationType}>
          {notification}
        </Alert>
        </div>
      )}
        <MetaTags>
          <title>통계 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>통계 목록</h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>조회 조건</div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>시작 날짜</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="date"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          value={startDate}
                                          onChange={handleStartDate}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>종료 날짜</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="date"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          value={endDate}
                                          onChange={handleEndDate}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-100"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"date"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TotalTable;
