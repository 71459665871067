import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row ,Badge} from "reactstrap";
import "./modal.scss";
import {
  adminRequestPost,
  adminUserPut,
  requestGet,
} from "../../../apis/request";
import { set } from "lodash";
//lch1721 2024년 8월 1일 이후 신규 로직에 의한 인센티브 상세 목록 
function LiekIncenListModal({
  setModal,
  data
}) {
  const [value, setValue] = useState([]);
  const formatDate = (dateString) => {
    if(dateString === null || dateString === undefined) return "";
    try{  
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Intl.DateTimeFormat('ko-KR', options).format(new Date(dateString));
    }catch(e){
      return "";
    }
  };

  // 가격을 3자리마다 쉼표를 추가하여 포맷팅하는 함수
  const formatPrice = (price) => {
    if(price === null || price === undefined) return "";
    try {
      return new Intl.NumberFormat('ko-KR', { style: 'decimal' }).format(Math.round(price)) + "원";  
    } catch (error) {
      return price;  
    }
    
  };


  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);



  const onClose = async () => {
    setModal(false);
  };

  const displayUsedDays = (usedDays) => {
    if(usedDays === null || usedDays === undefined) return "";
    try{
      return Number(usedDays) >= 0 ? usedDays : '';
    }catch(e){
      return "";
    }
  };

  function likeCouponFunction(data) {
    let likeCoupons = [];
    if (data && data.likeCoupon) {
      likeCoupons.push(
        <span key="paid" >
          {`유료 ${data.likeCoupon}개`}
        </span>
      );
    }
    if (data && data.earlyBirdLikeCoupon) {
      likeCoupons.push(
        <span key="earlybird" >
          {`얼리버드 ${data.earlyBirdLikeCoupon}개`}
        </span>
      );
    }
    if (data && data.freeLikeCoupon) {
      likeCoupons.push(
        <span key="free" >
          {`무료 ${data.freeLikeCoupon}개`}
        </span>
      );
    }
  
    return <>{likeCoupons}</>;
  }
  function likeCouponFunction2(data) {
    let likeCoupons = '';
    if (data && data.likeCoupon) {
      likeCoupons+=`유료 ${data.likeCoupon}개`

    }
    if (data && data.earlyBirdLikeCoupon) {
      if (likeCoupons.length >0){
        likeCoupons+=', '
      }
      likeCoupons+=`얼리버드 ${data.earlyBirdLikeCoupon}개`

    }
    if (data && data.freeLikeCoupon) {
      if (likeCoupons.length >0){
        likeCoupons+=', '
      }
      likeCoupons+=`무료 ${data.freeLikeCoupon}개`

    }
  
    return likeCoupons;
  }

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="12" md="11" lg="9" xl="8">
            <div className="modalOverlay" />
            <Card className="modalCard py-3 ps-3 pe-2">
              <h2>횟수제 인센 내역</h2>
              <table striped className="mt-3 border-table" >
                <thead>
                  <tr>
                    <th>매칭날짜</th>
                    <th>인센타입</th>
                    <th>사용자</th>
                    <th>사용 매칭권 차감금액</th>
                    <th>상대방</th>
                    <th>상대방 사용 매칭권 차감금액</th>
                    <th>차감금액 합계</th>
                    <th>인센 비율</th>
                    <th>인센티브</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={`incen-${index}`} style={{ textAlign: 'center' }}>
                      <td>{ item.matchDate}</td> 
                      <td>{item.incenType}</td>
                      <td>{item.name}</td>
                      <td>  { item.types.map(x=>`${formatPrice(x.incen)}`).join(', ')}</td>
                      <td className={`${item.incenType==='상담' ? 'text-secondary opacity-50' : 'text-dark'}`}>{item.targetName}</td>
                      <td className={`${item.incenType==='상담' ? 'text-secondary opacity-50' : 'text-dark'}`}>  { item.targetTypes.map(x=>`${formatPrice(x.incen)}`).join(', ')}</td>  
                      <td> {formatPrice(item.incen*(100/ item.ratio))}</td>
                      <td>  { item.ratio} %</td>  
                      <td style={{ textAlign: 'right' }} >{formatPrice(item.incen)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row className="w-100 m-0 mt-3">
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    color="danger"
                    style={{ marginRight: "10px" }}
                    onClick={() => onClose()}
                  >
                    닫기
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default LiekIncenListModal;
