import React, { useCallback, useEffect, useState } from "react";
import { Row, Col ,Card,  Button,Container} from "reactstrap";
import moment from "moment";
import { adminUserPut, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import { useIntroMemberListStore} from "../../store/zustand/state";

//lch1721 - 소개 목록 탭

function IntroMemberList({ id, callAPI, resetAPI }) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(1);
  const [lastpage, setLastpage] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const { list, updateList, updateActiveNum } = useIntroMemberListStore();
  const [sendDateUpdateModal, setSendDateUpdateModal] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [isBeforeApiCalled  , setBeforeApiCalled] = useState(1);
  const onClose = () => {
    setUpdateId("");
    setDate("");
    setHour("");
    setMinute("");
    setSendDateUpdateModal(false);
  };

  const onOpen = (id,sendDate) => {
    let [datepart,timepart]=sendDate.split(' ')
    setDate(datepart)
    console.log(datepart)
    let [h,m,s]=timepart.split(':')
    setHour(h)
    setMinute(m)
    setUpdateId(id);
    setSendDateUpdateModal(true);
  };
  const columns = [
    {
      dataField: "introduceeName",
      text: "소개 된 사람(이름)",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
            href={`/member/detail/${row.introduceeId}`}
            style={{ color: "#495057" }}
            target="_blank"
            rel="noreferrer"
          >
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "introduceeGender",
      text: "소개 된 사람 성별",
      sort: true,
    },
    {
      dataField: "introduceeGrade",
      text: "소개 된 사람 등급",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "소개 일자",
      sort: true,
    },
    {
      dataField: "introduceStatus",
      text: "상태",
      sort: true,
    },
    {
      dataField: "cancleBtn",
      text: "예약취소",
      formatter: (cellContent, row, rowIndex) => {
        if (row.introduceStatus === "예약 발송") {
          return (
            <button
              onClick={(e) => handleRejectClick(row.id)}
              className="btn btn-danger btn-sm"
            >
              예약취소
            </button>
          );
        }
      },
    },
    {
      dataField: "sendDate",
      text: "예약 시간",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        if (row.introduceStatus === "예약 발송") {

          return (
            <div>
              {cellContent}
              <button
              onClick={(e) => onOpen(row.id, row.sendDate)}
              className="btn btn-info btn-sm ms-3"
            >
              변경
            </button>
            </div>

          );
        }
      },
    },
    {
      dataField: "requestTime",
      text: "발송 시간",
      sort: true,
    },
    {
      dataField: "sendStatus",
      text: "알림톡 발송 완료",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };
  const handleRejectClick = async (id) => {
    if (window.confirm("예약 발송 취소 하시겠습니까?")) {
      try {
        let body = {
          id: id,
        };
        const response = await adminUserPut(`/api/introduce/cancel`, body);
        alert("예약 발송 취소 하였습니다.");
        callAPI();
        callInfo();
      } catch (error) {
        console.log(error);
        alert("다시 시도해 주세요.");
      }
    }
  };

  const sendDateUpdateClick = async () => {
    if (window.confirm("예약 시간을 변경 하시겠습니까?")) {
      let sendDate=`${date} ${hour}:${minute}:00`
      let utcDate = moment(sendDate, "YYYY-MM-DD HH:mm:ss")
      .utcOffset(9) // KST는 UTC+9
      .utc()        // UTC로 변환
      .format("YYYY-MM-DD HH:mm:ss");
    
    console.log("UTC 시간:", utcDate ,updateId);
      try {
        let body = {
          id: updateId,
          sendDate:utcDate
        };
        const response = await adminUserPut(`/api/introduce/pending/update`, body);
        alert("예약 시간을 변경 하였습니다.");
        onClose()
        callInfo()
      } catch (error) {
        console.log(error);
        let message='예약 시간을 변경을 실패하였습니다.'
        if (error.data&&error.data.message){
          message+=`(${error.data.message})`
        }
        alert(message);
      }
    }
  };
  const callInfo = useCallback(async () => {
    const params = new URLSearchParams();
    if (list) {
      for (var key in list) {
        if (list[key]) {
          params.append(key, list[key]);
        }
      }
    }
    const memberResponse = await requestGet(`/api/introduce/list/${id}?${params}`);
    setMemberList(memberResponse?.data);
    setLastpage(memberResponse?.totalPage);
    setTotalCount(memberResponse?.totalCount);
  }, [list]);

  useEffect(() => {
    setActiveNum(list.page);
    if (id && resetAPI) {
      callInfo();
    }
  }, [callInfo, id, resetAPI, list]);

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          introduceeGender: item.introduceeGender,
          introduceeName: item.introduceeName,
          createdAt: item.createdAt,
          requestTime: item.requestTime,
          introduceeGrade: item.introduceeGrade,
          introduceeId: item.introduceeId,
          sendDate: item.introduceStatus === "pending" ? item.sendDate : "",
          sendStatus: item.sendStatus ? "완료" : "미완료",
          introduceStatus:
            item.introduceStatus === "request"
              ? "발송"
              : item.introduceStatus === "pending"
              ? "예약 발송"
              : item.introduceStatus === "accept"
              ? "수락"
              : item.introduceStatus === "reject"
              ? "거절"
              : item.introduceStatus === "cancel"
              ? "취소"
              : item.introduceStatus === "fail"
              ? "실패"
              : "",
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">소개 목록</h3>
      <p className="card-title-desc"></p>
      <ToolkitProvider
          keyField="id"
          columns={columns}
          data={productData}
          search
      >
        {(toolkitProps) => (
            <React.Fragment>
              <Row>
                <Col xl="12">
                  <div className="text-end customTotalcount">
                    검색결과 (
                    {String(totalCount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    개의 검색결과가 있습니다)
                  </div>
                  <div className="table-responsive">
                    <BootstrapTable
                        keyField={"id"}
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                    />
                  </div>
                </Col>
              </Row>
              {/* <div ref={btnref} style={{ height: "auto" }}> */}
              {!lastpage ? null : (
                  <Row className="w-100 m-0 p-0 py-3">
                    <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                      <PaginationAPIWrap
                          pageNumber={pageNumber}
                          setpageNumber={setpageNumber}
                          lastpage={lastpage}
                          activeNum={activeNum}
                          setActiveNum={updateActiveNum}
                      />
                    </Col>
                    <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                      <MobilePaginationAPIWrap
                          pageNumber={mobilepageNumber}
                          setpageNumber={setMobilepageNumber}
                          lastpage={lastpage}
                          activeNum={activeNum}
                          setActiveNum={updateActiveNum}
                      />
                    </Col>
                  </Row>
              )}
            </React.Fragment>
        )}
      </ToolkitProvider>
      {sendDateUpdateModal ? (
<Container fluid className="modalWrap">
  <Row className="h-100 d-flex align-items-center justify-content-center">
    <Col xs="12" sm="10" md="8" lg="6" xl="5">
      <div className="modalOverlay" />
      <Card
        className="modalCard p-4"
        style={{
          position: "relative",
          borderRadius: "15px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 className="text-center mb-4">예약시간 수정</h2>
        <Row className="mb-3">
          <div>
            <div className="input-group">
              <input
                type="date"
                className="form-control customInputLeft"
                name="firstDate"
                id="firstDateLabel"
                min={moment().format("YYYY-MM-DD")}
                max="9999-12-31"
                value={date}
                onChange={(e) =>
                  setDate(moment(e.target.value).format("YYYY-MM-DD"))
                }
                style={{ maxWidth: "9rem", borderRadius: "5px 0 0 5px" }}
              />
              <select
                className="form-select"
                name="firstDateTime"
                style={{ maxWidth: "5rem" }}
                value={hour}
                onChange={(e) => setHour(e.target.value)}
              >
                {Array.from({ length: 14 }, (_, i) => i + 9).map((hour) => (
                  <option key={hour} value={hour.toString().padStart(2, "0")}>
                    {hour.toString().padStart(2, "0")}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                name="firstDateTime"
                style={{
                  maxWidth: "5rem",
                  borderRadius: "0 5px 5px 0",
                }}
                value={minute}
                onChange={(e) => setMinute(e.target.value)}
              >
                {["00", "10", "20", "30", "40", "50"].map((min) => (
                  <option key={min} value={min}>
                    {min}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Row>
         <Row
         className="w-100 px-0 pt-3 pb-3"
         style={{
           borderTop: "1px solid #ced4da",
           backgroundColor: "#fff",
           position: "sticky",
           bottom: "0",
         }}
       >
         <Col className="text-end">
           <Button
             id="masterAccesBtn"
             color="danger"
             style={{ marginRight: "10px" }}
             onClick={() => onClose()}
           >
             닫기
           </Button>
           <Button
             id="masterAccesBtn"
             color="primary"
             onClick={() => sendDateUpdateClick()}
           >
             확인
           </Button>
         </Col>
       </Row>
      
      </Card>
    </Col>
  </Row>
</Container>
      ) : null}
    </React.Fragment>
  );
}

export default IntroMemberList;
