import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntroListStore } from "../../store/zustand/state";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button,Container } from "reactstrap";

import { adminUserPut, requestGet } from "../../apis/request";
import moment from "moment";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";

function IntroList(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [sendDateUpdateModal, setSendDateUpdateModal] = useState(false);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [managers, setManagers] = useState([]);
  const [name, setName] = useState("");
  const [manager, setManager] = useState("");

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [updateId, setUpdateId] = useState("");
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const { list, updateList, updateActiveNum } = useIntroListStore();
  const onClose = () => {
    setUpdateId("");
    setDate("");
    setHour("");
    setMinute("");
    setSendDateUpdateModal(false);
  };

  const onOpen = (id,sendDate) => {
    let [datepart,timepart]=sendDate.split(' ')
    setDate(datepart)
    console.log(datepart)
    let [h,m,s]=timepart.split(':')
    setHour(h)
    setMinute(m)
    setUpdateId(id);
    setSendDateUpdateModal(true);
  };
  const columns = [
    {
      dataField: "introduceStatus",
      text: "상태",
      sort: true,
    },
    {
      dataField: "recipientName",
      text: "소개 받은 사람 이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
            href={`/member/detail/${row.recipientId}`}
            style={{ color: "#495057" }}
            target="_blank"
            rel="noreferrer"
          >
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "recipientGrade",
      text: "소개 받은 사람 등급",
      sort: true,
    },
    {
      dataField: "introduceeName",
      text: "소개 된 사람 이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
            href={`/member/detail/${row.introduceeId}`}
            style={{ color: "#495057" }}
            target="_blank"
            rel="noreferrer"
          >
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "introduceeGrade",
      text: "소개 된 사람 등급",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "소개 일자",
      sort: true,
    },
    {
      dataField: "cancleBtn",
      text: "예약취소",
      formatter: (cellContent, row, rowIndex) => {
        if (row.introduceStatus === "예약 발송") {
          return (
            <button
              onClick={(e) => handleRejectClick(row.id)}
              className="btn btn-danger btn-sm"
            >
              예약취소
            </button>
          );
        }
      },
    },
    {
      dataField: "sendDate",
      text: "예약 시간",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        if (row.introduceStatus === "예약 발송") {

          return (
            <div>
              {cellContent}
              <button
              onClick={(e) => onOpen(row.id, row.sendDate)}
              className="btn btn-info btn-sm ms-3"
            >
              변경
            </button>
            </div>

          );
        }
      },
    },
    {
      dataField: "requestTime",
      text: "발송 시간",
      sort: true,
    },
    {
      dataField: "sendStatus",
      text: "알림톡 발송 완료",
      sort: true,
    },
  ];

  const defaultSorted = [];

  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callManagerApi();
  }, []);

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      const memberResponse = await requestGet(`/api/introduce/list?${params}`);
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  const handleRejectClick = async (id) => {
    if (window.confirm("예약 발송 취소 하시겠습니까?")) {
      try {
        let body = {
          id: id,
        };
        const response = await adminUserPut(`/api/introduce/cancel`, body);
        alert("예약 발송 취소 하였습니다.");
        callInfo()
      } catch (error) {
        console.log(error);
        alert("다시 시도해 주세요.");
      }
    }
  };

  const sendDateUpdateClick = async () => {
    if (window.confirm("예약 시간을 변경 하시겠습니까?")) {
      let sendDate=`${date} ${hour}:${minute}:00`
      let utcDate = moment(sendDate, "YYYY-MM-DD HH:mm:ss")
      .utcOffset(9) // KST는 UTC+9
      .utc()        // UTC로 변환
      .format("YYYY-MM-DD HH:mm:ss");
    
    console.log("UTC 시간:", utcDate ,updateId);
      try {
        let body = {
          id: updateId,
          sendDate:utcDate
        };
        const response = await adminUserPut(`/api/introduce/pending/update`, body);
        alert("예약 시간을 변경 하였습니다.");
        onClose()
        callInfo()
      } catch (error) {
        console.log(error);
        let message='예약 시간을 변경을 실패하였습니다.'
        if (error.data&&error.data.message){
          message+=`(${error.data.message})`
        }
        alert(message);
      }
    }
  };

  useEffect(() => {
    setActiveNum(list.page);
    setName(list.name);
    setManager(list.manager);
    callInfo();
  }, [callInfo, list]);

  const gohistory = () => {
    updateList(1, name, manager);
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          introduceeGender: item.introduceeGender,
          introduceeName: (item.introduceeName +=
            item.introduceeGender === "남성"
              ? "(남)"
              : item.introduceeGender === "여성"
              ? "(여)"
              : ""),
          introduceeId: item.introduceeId,
          recipientGender: item.recipientGender,
          recipientGrade: item.recipientGrade,
          recipientId: item.recipientId,
          createdAt: item.createdAt,
          introduceeGrade: item.introduceeGrade,
          sendDate: item.sendDate === "Invalid date" ? "" : item.sendDate,
          requestTime: item.requestTime,
          sendStatus: item.sendStatus ? "완료" : "미완료",
          introduceStatus:
            item.introduceStatus === "request"
              ? "발송"
              : item.introduceStatus === "pending"
              ? "예약 발송"
              : item.introduceStatus === "accept"
              ? "수락"
              : item.introduceStatus === "reject"
              ? "거절"
              : item.introduceStatus === "cancel"
              ? "취소"
              : item.introduceStatus === "fail"
              ? "실패"
              : "",
          recipientName: (item.recipientName +=
            item.recipientGender === "남성"
              ? "(남)"
              : item.recipientGender === "여성"
              ? "(여)"
              : ""),
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  useEffect(() => {
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, productData]);







  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>소개 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>소개 목록</h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>조회 조건</div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>이름</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="이름을 입력해 주세요."
                                          aria-label="이름을 입력해 주세요."
                                          value={name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                          // onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>매니저 이름</span>
                                    </Col>
                                    <Col
                                      className="m-0 p-0"
                                      style={{
                                        borderBottom: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="h-100">
                                        <select
                                          id="counselor_genderLabel"
                                          className="noborder form-select h-100"
                                          value={manager}
                                          onChange={(e) =>
                                            setManager(e.target.value)
                                          }
                                          style={{ width: "100%" }}
                                        >
                                          <option value="">전체</option>
                                          {managers?.map((item, i) => {
                                            return (
                                              <option value={item.name} key={i}>
                                                {item.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-100"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <div className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </div>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {sendDateUpdateModal ? (
<Container fluid className="modalWrap">
  <Row className="h-100 d-flex align-items-center justify-content-center">
    <Col xs="12" sm="10" md="8" lg="6" xl="5">
      <div className="modalOverlay" />
      <Card
        className="modalCard p-4"
        style={{
          position: "relative",
          borderRadius: "15px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 className="text-center mb-4">예약시간 수정</h2>
        <Row className="mb-3">
          <div>
            <div className="input-group">
              <input
                type="date"
                className="form-control customInputLeft"
                name="firstDate"
                id="firstDateLabel"
                min={moment().format("YYYY-MM-DD")}
                max="9999-12-31"
                value={date}
                onChange={(e) =>
                  setDate(moment(e.target.value).format("YYYY-MM-DD"))
                }
                style={{ maxWidth: "9rem", borderRadius: "5px 0 0 5px" }}
              />
              <select
                className="form-select"
                name="firstDateTime"
                style={{ maxWidth: "5rem" }}
                value={hour}
                onChange={(e) => setHour(e.target.value)}
              >
                {Array.from({ length: 14 }, (_, i) => i + 9).map((hour) => (
                  <option key={hour} value={hour.toString().padStart(2, "0")}>
                    {hour.toString().padStart(2, "0")}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                name="firstDateTime"
                style={{
                  maxWidth: "5rem",
                  borderRadius: "0 5px 5px 0",
                }}
                value={minute}
                onChange={(e) => setMinute(e.target.value)}
              >
                {["00", "10", "20", "30", "40", "50"].map((min) => (
                  <option key={min} value={min}>
                    {min}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Row>
         <Row
         className="w-100 px-0 pt-3 pb-3"
         style={{
           borderTop: "1px solid #ced4da",
           backgroundColor: "#fff",
           position: "sticky",
           bottom: "0",
         }}
       >
         <Col className="text-end">
           <Button
             id="masterAccesBtn"
             color="danger"
             style={{ marginRight: "10px" }}
             onClick={() => onClose()}
           >
             닫기
           </Button>
           <Button
             id="masterAccesBtn"
             color="primary"
             onClick={() => sendDateUpdateClick()}
           >
             확인
           </Button>
         </Col>
       </Row>
      
      </Card>
    </Col>
  </Row>
</Container>
      ) : null}
    </React.Fragment>
  );
}

export default IntroList;
