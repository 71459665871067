import React, { useCallback, useEffect, useRef, useState } from "react";
import { useCashListStore } from "../../store/zustand/state";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";

import { adminUserPut, requestDelete, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import "../Consult/consult.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import moment from "moment";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import CashModal from "../Member/Modal/CashModal";

function Cash(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [username, setuserName] = useState("");
  const [usergender, setuserGender] = useState("");
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [manager, setManager] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userId, setUserId] = useState("");
  const [dataId, setDataId] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [totalSum, setTotalSum] = useState(0);
  const [managers, setManagers] = useState([]);
  const [managerRole, setManagerRole] = useState(false);

  const [tableHeight, setTableHeight] = useState(0);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const history = useHistory();
  const { list, updateList, updateActiveNum } = useCashListStore();

  const columns = [
    {
      dataField: "merchantId",
      text: "수기",
      sort: true,
    },
    {
      dataField: "orderer",
      text: "이름",
      sort: true,
    },
    {
      dataField: "manager",
      text: "담당 매니저",
      sort: true,
    },
    {
      dataField: "phone",
      text: "연락처",
      sort: true,
    },
    {
      dataField: "email",
      text: "이메일",
      sort: true,
    },
    {
      dataField: "productName",
      text: "상품 이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        let productName=''
        if (row.mainName ) {
          productName+=row.mainName+' ';
        }
        productName +=cellContent;
        return productName
      },
    },
    {
      dataField: "productPrice",
      text: "가격",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "구매 시간",
      sort: true,
    },
    {
      dataField: "status",
      text: "상태",
      sort: true,
    },
    {
      dataField: "action",
      text: "삭제",
      formatter: (cellContent, row, rowIndex) => {
        if (row.merchantId === "O") {
          return (
            <button
              onClick={(e) => deletePurchase(e, row.id)}
              className="btn btn-dark btn-sm"
            >
              삭제
            </button>
          );
        }
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setManagerRole(localStorage.getItem("loginUserType") === "manager");
    callManagerApi();
  }, []);

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      const memberResponse = await requestGet(
        `/api/purchase/list?status=complete&${params}`
      );
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
      setTotalSum(memberResponse?.totalSum);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  useEffect(() => {
    setActiveNum(list.page);
    setuserName(list.name);
    setManager(list.counselor);
    setStartDate(list.startDate);
    setEndDate(list.endDate);
    callInfo();
  }, [callInfo, list]);

  const deletePurchase = async (e, id) => {
    e.stopPropagation();
    if (window.confirm("정말 삭제 하시겠습니까?")) {
      try {
        let body = {
          purchaseId: id,
        };
        await adminUserPut(`/api/purchase/direct/cancel`, body);
        alert("삭제 되었습니다.");
        callInfo();
      } catch (error) {
        console.log(error);
        if (error.data.message) {
          alert(error.data.message);
        }
      }
    }
  };

  const callApi = async () => {
    const response = await requestGet(`/api/product/list/admin`);
    setData(response.data);
  };

  useEffect(() => {
    callApi();
  }, []);

  const gohistory = () => {
    updateList(1, username, manager, startDate, endDate);
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          userId: item.userId,
          orderer: item.orderer,
          merchantId: item.merchantId ? "X" : "O",
          gender: item.gender,
          phone: item.phone,
          email: item.email,
          manager: item.manager,
          status:
            item.status === "prepare"
              ? "결제 요청"
              : item.status === "complete"
              ? "결제 완료"
              : item.status === "refund-request"
              ? "환불 요청"
              : item.status === "refund-accept"
              ? "환불 완료"
              : "",
          mainName:item.mainName,
          productName: item.productName,
          createdAt: item.createdAt,
          productPrice: item.productPrice
            ? String(item.productPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "",
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  const handlename = (e) => {
    setuserName(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      gohistory();
    }
  };

  const onStartDate = (e) => {
    if (e.target.value) {
      setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
    } else {
      setStartDate("");
    }
  };

  const onEndDate = (e) => {
    if (e.target.value) {
      setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
    } else {
      setEndDate("");
    }
  };

  const handleRowClick = (e, row) => {
    setDataId(row.id);
    setUserId(row.userId);
    setModalType("read");
    setModalOpen(true);
  };

  // rowEvents 객체 정의
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(e, row, rowIndex);
    },
  };

  useEffect(() => {
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, productData]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>결제 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>결제 목록</h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>조회 조건</div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>이름</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="이름을 입력해 주세요."
                                          aria-label="이름을 입력해 주세요."
                                          value={username}
                                          onChange={handlename}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>담당 매니저</span>
                                    </Col>
                                    <Col
                                      className="m-0 p-0"
                                      style={{
                                        borderBottom: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="h-100">
                                        <select
                                          id="manager_genderLabel"
                                          className="noborder form-select h-100"
                                          value={manager}
                                          onChange={(e) =>
                                            setManager(e.target.value)
                                          }
                                        >
                                          <option value="">전체</option>
                                          {managers?.map((item, i) => {
                                            return (
                                              <option value={item.name} key={i}>
                                                {item.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>구매시간 시작일</span>
                                    </Col>
                                    <Col
                                      className="m-0 p-0"
                                      style={{
                                        borderBottom: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="h-100">
                                        <input
                                          type="date"
                                          className="js-input-mask form-control noborder w-auto"
                                          name="startDate"
                                          id="startDateLabel"
                                          max="9999-12-31"
                                          value={startDate}
                                          onChange={onStartDate}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>구매시간 종료일</span>
                                    </Col>
                                    <Col
                                      className="m-0 p-0"
                                      style={{
                                        borderBottom: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="h-100">
                                        <input
                                          type="date"
                                          className="js-input-mask form-control noborder w-auto"
                                          name="endDate"
                                          id="endDateLabel"
                                          max="9999-12-31"
                                          value={endDate}
                                          onChange={onEndDate}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-100"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            {!managerRole?                       <Col className="text-start customTotalcount">
                            총합 : {String(totalSum).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}원
                          </Col>:null}
   
                          <Col className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  rowEvents={rowEvents}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {modalOpen ? (
        <CashModal
          setModal={setModalOpen}
          productData={data}
          setModalType={setModalType}
          modalType={modalType}
          // userId={id}
          setDataId={setDataId}
          dataId={dataId}
          callInfo={callInfo}
        />
      ) : null}
    </React.Fragment>
  );
}

export default Cash;
