import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIncentiveHistoryStore } from "../../store/zustand/state";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useParams } from "react-router-dom";

import { requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import moment from "moment";
import { subscribe } from "toastr";
import { set } from "lodash";
import SubscribeModal from "../Member/Modal/SubscribeModal";
import NewSubscribeModal from "../Member/Modal/NewSubscribeModal";
import LiekIncenListModal from "./Modal/likeIncenListModal";

//현재 사용하고 있는 인센티브 목록 페이지 


function IncentiveHistory(props) {
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tableHeight, setTableHeight] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [newData, setNewData] = useState(true);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [likeModalOpen, setLikeModalOpen] = useState(false);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const [subsListData, setSubsListData] = useState([]);
  const [likeListData, setLikeListData] = useState([]);
  const { list, updateList, updateActiveNum } = useIncentiveHistoryStore();

  const { page } = useParams();


  const showSubsList = (e, list) => {
    console.log("list",list);
    if(newData){
      setNewModalOpen(true);
    } else {
      setModalOpen(true);
    }
    
    setSubsListData(list);
  };

  const showLikeList = (e, list) => {
    console.log("list",list);
    setLikeModalOpen(true);

    
    setLikeListData(list);
  };



  const columns = [
    {
      dataField: "manager",
      text: "이름",
      sort: true,
    },
    {
      dataField: "counseling",
      text: "상담 인센티브",
      sort: true,
      classes:"align-right"
    },
    {
      dataField: "introduce",
      text: "소개 인센티브",
      sort: true,
      classes:"align-right"
    },
    {
      dataField: "subscribe",
      text: "기간제 인센티브",
      sort: true,
      classes:"align-right"
    },
    { dataField: "matchCount", text: "맞호감된 수", sort: true,classes:"align-right" },
    { dataField: "introduceCount", text: "소개", sort: true,classes:"align-right" },
    { dataField: "counselingCount", text: "상담", sort: true,classes:"align-right" },
    { dataField: "nonFree", text: "유료 매칭권 사용 수", sort: true,classes:"align-right" },
    { dataField: "free", text: "무료 매칭권 사용 수", sort: true,classes:"align-right" },
    { dataField: "subscribeCount", text: "기간제 구매 수", sort: true,classes:"align-right" },

    {
      dataField: "likeAction",
      text: "횟수제 내역",
      formatter: (cellContent, row, rowIndex) => {
        
        if (Number(row.likeCouponListCount) >0) {
          return (
            <button
              onClick={(e) =>showLikeList(e,row.likeCouponList) }
              className="btn btn-dark btn-sm"
            >
              보기
            </button>
          )
        } 
      },
    },
    {
      dataField: "action",
      text: "기간제 내역",
      formatter: (cellContent, row, rowIndex) => {
        
        if (Number(row.subscribeCount) >0) {
          return (
            <button
              onClick={(e) =>showSubsList(e,row.subsList) }
              className="btn btn-dark btn-sm"
            >
              보기
            </button>
          )
        } 
      },
    },
    {
      dataField: "total",
      text: "총 합",
      sort: true,
      classes:"align-right",
    },
  ];

  const defaultSorted = [];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: editData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const callApi = async () => {
    let urlBase = "/api/admin/manager/incen/test";

    try {
      const params = new URLSearchParams();
      if (list) {
        if(list.startDate){
          if(list.endDate){
            let startDate = moment(list.startDate, 'YYYY-MM-DD');
            let endDate = moment(list.endDate, 'YYYY-MM-DD');
            // 기준 날짜
            const referenceDate = moment('2024-08-01', 'YYYY-MM-DD');
            if(startDate.isAfter(endDate)){
              alert("시작일이 종료일보다 클 수 없습니다.");
              return;
            }

            // isBefore: startDate와 endDate가 모두 referenceDate보다 과거에 있는 경우
            const isBefore = startDate.isBefore(referenceDate) && endDate.isBefore(referenceDate);

            console.log(`isBefore: ${isBefore}`); // true 또는 false

            // isError: startDate가 referenceDate보다 과거이고, endDate가 referenceDate 이후 또는 같은 경우
            const isError = startDate.isBefore(referenceDate) && !endDate.isBefore(referenceDate);

            console.log(`isError: ${isError}`); // true 또는 false

            if(isError){
              alert("2024년 8월 1일 이전 데이터는 이후 데이터와 같이 조회할 수 없습니다.");
              return;
            }
            if(isBefore){
              urlBase = "/api/admin/manager/incen";
              setNewData(false);
            }  else {
              setNewData(true);
            }
          } else {
            alert("종료일을 설정해주세요");
            return;
          }
        } else if(list.endDate){
          alert("시작일을 설정해주세요");
          return;
        } else {
          setNewData(true);
        }

        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      // const response = await requestGet(`/api/admin/manager/incen?${params}`);
      const response = await requestGet(urlBase + `?${params}`);
      setData(response?.data);
      setLastpage(response?.totalPage);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setActiveNum(list.page);
    setStartDate(list.startDate);
    setEndDate(list.endDate);
    callApi();
  }, [list]);

  const gohistory = () => {
    updateList(1, startDate, endDate);
  };

  useEffect(() => {
    if (data?.length > 0) {
      let arr = [];
      data.forEach((item) =>
        arr.push({
          id: item.id,
          manager: item.manager,
          counseling: item.counseling ? String(Math.round(item.counseling)).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
          introduce: item.introduce ? String(Math.round(item.introduce)).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
          matchCount: item.matchCount,
          introduceCount: item.introduceCount,
          counselingCount: item.counselingCount,
          nonFree: item.nonFree,
          free: item.free,
          total: item.total ? String(Math.round(item.total)).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
          subscribe: item.subscribe ?  String(Math.round(item.subscribe)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "",
          subscribeCount: item.subsList ? String(item.subsList.length) : "0",
          likeCouponListCount: item.  likeCouponList ? String(item.  likeCouponList.length) : "0",
          likeCouponList:item.likeCouponList,
          subsList : item.subsList,
        })
      );
      setEditData(arr);
    }
  }, [data]);

  const onStartDate = (e) => {
    if (e.target.value) {
      setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
    } else {
      setStartDate("");
    }
  };

  const onEndDate = (e) => {
    if (e.target.value) {
      setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
    } else {
      setEndDate("");
    }
  };

  useEffect(() => {
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, editData]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>인센티브 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={editData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>
                            인센티브 목록
                          </h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>조회 조건</div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>시작 날짜</span>
                                    </Col>
                                    <Col
                                      className="m-0 p-0"
                                      style={{
                                        borderBottom: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="h-100">
                                        <input
                                          type="date"
                                          className="w-auto js-input-mask form-control noborder"
                                          value={startDate}
                                          onChange={onStartDate}
                                          // max="9999-12-31"
                                          max={endDate ? endDate : "9999-12-31"}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>종료 날짜</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="date"
                                          className="w-auto js-input-mask form-control noborder"
                                          value={endDate}
                                          onChange={onEndDate}
                                          min={startDate ? startDate : null}
                                          max="9999-12-31"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-100"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {modalOpen ? (
        <SubscribeModal
          setModal={setModalOpen}
          data={subsListData}
        />
      ) : null}
      {newModalOpen ? (
        <NewSubscribeModal
          setModal={setNewModalOpen}
          data={subsListData}
        />
      ) : null}
      {likeModalOpen ? (
        <LiekIncenListModal
          setModal={setLikeModalOpen}
          data={likeListData}
        />
      ) : null}
    </React.Fragment>
  );
}

export default IncentiveHistory;
